<template>
  <!-- 结算管理 - 开票管理 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">开票管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="开票单位" class="searchboxItem ci-full">
              <span class="itemLabel">开票单位:</span>
              <el-select clearable placeholder="请选择开票单位" size="small" v-model="retrievalData.openCompany">
                <el-option size="small" v-for="item in openCompanyList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="收票单位" class="searchboxItem ci-full">
              <span class="itemLabel">收票单位:</span>
              <el-select size="small" v-model="retrievalData.closeCompany" value-key="compId" remote
                :remote-method="getCompanyList" filterable clearable placeholder="输入关键字" @change="bindChange">
                <el-option v-for="item in CompanyList" :key="item.closeCompanyId" :label="item.closeCompany"
                  :value="item.closeCompanyId"></el-option>
              </el-select>
            </div>
            <div title="票据种类" class="searchboxItem ci-full">
              <span class="itemLabel">票据种类:</span>
              <el-select size="small" v-model="retrievalData.invoiceType" placeholder="请选择票据种类" clearable>
                <el-option v-for="item in invoice_type" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="申请人" class="searchboxItem ci-full">
              <span class="itemLabel">申请人:</span>
              <el-input v-model="retrievalData.fullName" clearable placeholder="请输入申请人" size="small"></el-input>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="申请类型" class="searchboxItem ci-full">
              <span class="itemLabel">申请类型:</span>
              <el-select size="small" v-model="retrievalData.applicationComp" placeholder="请选择申请类型" clearable>
                <el-option v-for="item in invoice_applicationComp" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="开票状态" class="searchboxItem ci-full">
              <span class="itemLabel">开票状态:</span>
              <el-select size="small" v-model="retrievalData.invoiceState" placeholder="请选择开票状态" clearable>
                <el-option v-for="item in invoicingStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="申请开票方" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">申请开票方:</span>
              <el-select size="small" v-model="retrievalData.compId" remote :remote-method="getCompanyList_1" filterable
                clearable placeholder="请至少输入两个字搜索"><el-option v-for="item in CompanyList_1List" :key="item.compId"
                  :label="item.compName" :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="有无结算单" class="searchboxItem ci-full">
              <span class="itemLabel">有无结算单:</span>
              <el-select size="small" v-model="retrievalData.hasBill" placeholder="请选择有无结算单" clearable>
                <el-option v-for="item in hasBillData" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="申请日期" class="searchboxItem ci-full" style="width: 428px"><span class="itemLabel">申请日期:</span>
              <el-date-picker clearable size="small" v-model="retrievalData.applicationDate" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div class="df">
              <el-button type="primary" size="small" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" size="small" class="bgc-bv" round @click="addEdit()">新增</el-button>
              <el-button type="primary" size="small" class="bgc-bv" round
                @click="exportSeeExaminationRoom()">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe><el-table-column label="序号" align="center"
                type="index" :index="indexMethod" width="100px" /><el-table-column label="开票单位" align="left"
                show-overflow-tooltip prop="openCompany" minWidth="140" />
              <el-table-column label="收票单位" align="left" show-overflow-tooltip prop="closeCompany" minWidth="140" />
              <el-table-column label="申请开票方" align="left" show-overflow-tooltip prop="compName" minWidth="140" />
              <el-table-column label="票据种类" align="left" show-overflow-tooltip prop="invoiceType" minWidth="100"><template
                  slot-scope="scope">
                  {{ $setDictionary("INVOICE_TYPE", scope.row.invoiceType) }}
                </template>
              </el-table-column>
              <el-table-column label="申请类型" align="left" show-overflow-tooltip prop="applicationComp"
                minWidth="100"><template slot-scope="scope">
                  {{
                    $setDictionary(
                      "INVOICE_APPLICATION_COMP",
                      scope.row.applicationComp
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column label="开票金额" align="left" show-overflow-tooltip prop="invoiceMoney" minWidth="100" />

              <el-table-column label="开票日期" align="center" show-overflow-tooltip width="120"><template
                  slot-scope="scope">{{
                    scope.row.invoiceDate | momentDate
                  }}</template>
              </el-table-column>
              <el-table-column label="开票说明" align="left" show-overflow-tooltip prop="invoiceExplain" minWidth="160" />
              <el-table-column label="申请人" align="left" show-overflow-tooltip prop="fullName" minWidth="100" />
              <el-table-column label="状态" align="left" show-overflow-tooltip prop="invoiceState" minWidth="100"><template
                  slot-scope="scope">
                  {{ $setDictionary("INVOICE_STATE", scope.row.invoiceState) }}
                </template>
              </el-table-column>
              <el-table-column label="有无结算单" align="left" show-overflow-tooltip prop="hasBill" minWidth="100"><template
                  slot-scope="scope">
                  {{ scope.row.hasBill ? '有' : '无' }}
                </template>
              </el-table-column>
              <el-table-column label="创建日期" align="center" show-overflow-tooltip width="120"><template
                  slot-scope="scope">{{
                    scope.row.createTime | momentDate
                  }}</template>
              </el-table-column>
              <el-table-column label="备注" align="left" show-overflow-tooltip prop="remark" minWidth="160" />
              <el-table-column label="操作" align="left" width="170px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="addEdit(scope.row)">{{
                    scope.row.applicationComp != "10" ? "查看" : "编辑"
                  }}</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="rejectz(scope.row)"
                    :disabled="scope.row.reject">驳回</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="completez(scope.row)"
                    :disabled="scope.row.complete">完成</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" :disabled="scope.row.applicationComp == '20'"
                    @click="printp(scope.row)">打印</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" v-if="scope.row.applicationComp == '20'"
                    :disabled="scope.row.invoiceState == '20' ||
                      scope.row.invoiceState == '40'
                      " @click="agreen(scope.row.invoiceId)">同意</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" v-if="scope.row.applicationComp == '20'"
                    :disabled="scope.row.invoiceState != '20'" @click="uploadFile(scope.row.invoiceId)">上传凭证</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 驳回弹框 -->
    <el-dialog title="驳回理由" :visible.sync="rejectzledialog" width="52%" top="10%">
      <div class="ovy-a bh" style="height: 200px">
        <el-input type="textarea" :rows="2" placeholder="请输入驳回理由" :show-word-limit="true" :maxlength="200"
          v-model="rejectzDta.remark">
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="rejectzledialog = false">取 消</el-button>
        <el-button @click="rejectzDetermine" class="bgc-bv" type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 打印 -->
    <el-dialog title="打印" :visible.sync="printploading" width="50%" top="2%" center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px"></div>
      </div>
    </el-dialog>
    <!-- 上传附件 - loading -->
    <el-dialog title="上传附件" :visible.sync="uploadfiledialog" width="35%" class="toVoidLoding">
      <el-form :model="InvoiceForm" :rules="rules" ref="rejectzDta" label-width="70px" class="demo-ruleForm basicData">
        <el-form-item label="发票编号" prop="InvoiceNo">
          <el-input v-model="InvoiceForm.InvoiceNo" clearable placeholder="请输入发票编号" size="small"></el-input>
        </el-form-item>
      </el-form>
      <div style="margin: 10 0 10px; display: flex; flex-direction: column">
        <el-upload class="upload-demo upload-btn upload-btndrag" :action="actionUrl" :on-error="handleError"
          :on-success="handleSuccess" :on-change="uploadChange1" :show-file-list="false" :auto-upload="false" drag>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div v-if="uploadfileList.length" style="margin-top: 10px; margin-left: 10px">
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span style="color: #409eff; margin-top: 10px" v-for="(item, index) in uploadfileList" :key="index">
              {{ item.voucherName }}
              <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
              <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px">预览</a>
              <a @click="reomveExl(index)" style="color: red; margin: 0 15px">删除</a>
            </span>
          </div>
        </div>
        <div v-else>
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" class="bgc-bv" round @click="uploadfileClose">取 消</el-button>
        <el-button type="primary" size="small" class="bgc-bv" round @click="uploadfileDetermine">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog title="预览" :visible.sync="previewLoding1" @close="uploadLodClose" width="50%" top="2%" center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px" v-if="fileType == 'pdf' || fileType == 'PDF'"></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "invoicingManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      retrievalData: {
        // 开票单位
        openCompany: "",
        // 收票单位
        closeCompany: "",
        // 票据种类
        invoiceType: "",
        // 申请人
        fullName: "",
        // 申请日期
        applicationDate: [],
        //申请类型
        applicationComp: "",
        // 开票状态
        invoiceState: "",
        // 申请开票方
        compId: "",
        // 有无结算单
        hasBill: "",
      },
      // 开票单位 - 下拉数据
      openCompanyList: [],
      // 收票单位 - 下拉数据
      CompanyList: [],
      // 申请开票方 - 下拉数据
      CompanyList_1List: [],
      // 票据种类 - 下拉数据
      invoice_type: [],
      // 申请类型 - 下拉数据
      invoice_applicationComp: [],
      // 开票状态 - 下拉数据
      invoicingStateList: [],
      // 有无结算单 - 下拉数据
      hasBillData: [{
        value: true,
        label: "有"
      }, {
        value: false,
        label: "无"
      }],
      // 驳回 - loading
      rejectzledialog: false,
      // 驳回 - 数据
      rejectzDta: {
        // 发票id
        invoiceId: "",
        // 驳回理由
        remark: "",
      },
      // 打印 - loading
      printploading: false,
      //上传附件
      uploadfiledialog: false,
      uploadfileList: [],
      previewLoding1: false,
      ImgSrc: "",
      InvoiceForm: {
        InvoiceNo: "",
      },
    };
  },
  created() {
    this.getAopenCompanyList();
    this.getInvoice_type();
    this.getInvoicingState();
  },
  mounted() { },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取字典 - 开票单位 - 下拉数据
    getAopenCompanyList() {
      let arr = this.$setDictionary("INVOICE_OPEN_COMPANY", "list");
      for (const key in arr) {
        this.openCompanyList.push({
          label: arr[key],
          value: key,
        });
      }
    },
    // 获取数据 - 收票单位
    getCompanyList(query) {
      this.$post("/biz/new/bill/invoice/company/pageList", {
        closeCompany: query,
      })
        .then((res) => {
          if (res.status == 0) {
            this.CompanyList = res.data.list || [];
          }
        })
        .catch(() => {
          return;
        });
    },
    // 获取数据 - 所属机构
    getCompanyList_1(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList_1List = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取字典 - 票据种类&&申请类型 - 下拉数据
    getInvoice_type() {
      let arr = this.$setDictionary("INVOICE_TYPE", "list");
      let applicationCompArr = this.$setDictionary(
        "INVOICE_APPLICATION_COMP",
        "list"
      );
      for (const key in arr) {
        this.invoice_type.push({
          label: arr[key],
          value: key,
        });
      }
      for (const key in applicationCompArr) {
        this.invoice_applicationComp.push({
          label: applicationCompArr[key],
          value: key,
        });
      }
    },
    // 获取字典 - 状态 - 下拉数据
    getInvoicingState() {
      let arr = this.$setDictionary("INVOICE_STATE", "list");
      for (const key in arr) {
        if (key != "50") {
          this.invoicingStateList.push({
            label: arr[key],
            value: key,
          });
        }
      }
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
      };
      // 开票单位
      if (this.retrievalData.openCompany) {
        params.openCompany = this.retrievalData.openCompany;
      }
      // 收票单位
      if (this.retrievalData.closeCompany) {
        params.closeCompany = this.retrievalData.closeCompany;
      }
      // 票据种类
      if (this.retrievalData.invoiceType) {
        params.invoiceType = this.retrievalData.invoiceType;
      }
      // 票据种类
      if (this.retrievalData.applicationComp) {
        params.applicationComp = this.retrievalData.applicationComp;
      }
      // 申请人
      if (this.retrievalData.fullName) {
        params.fullName = this.retrievalData.fullName;
      }
      // 申请日期
      if (this.retrievalData.applicationDate) {
        params.createTimeBegin = this.retrievalData.applicationDate[0];
        params.createTimeEnd = this.retrievalData.applicationDate[1];
      }
      // 开票状态
      if (this.retrievalData.invoiceState) {
        params.invoiceState = this.retrievalData.invoiceState;
      }
      // 申请开票方
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      // 有无结算单
      if (typeof this.retrievalData.hasBill != 'string') {
        params.hasBill = this.retrievalData.hasBill;
      }
      this.doFetch({
        url: "/biz/new/bill/invoice/pageList",
        params,
        pageNum,
      });
    },
    // 导出
    exportSeeExaminationRoom() {
      const params = {};
      // 开票单位
      if (this.retrievalData.openCompany) {
        params.openCompany = this.retrievalData.openCompany;
      }
      // 收票单位
      if (this.retrievalData.closeCompany) {
        params.closeCompany = this.retrievalData.closeCompany;
      }
      // 票据种类
      if (this.retrievalData.invoiceType) {
        params.invoiceType = this.retrievalData.invoiceType;
      }
      // 票据种类
      if (this.retrievalData.applicationComp) {
        params.applicationComp = this.retrievalData.applicationComp;
      }
      // 申请人
      if (this.retrievalData.fullName) {
        params.fullName = this.retrievalData.fullName;
      }
      // 申请日期
      if (this.retrievalData.applicationDate) {
        params.createTimeBegin = this.retrievalData.applicationDate[0];
        params.createTimeEnd = this.retrievalData.applicationDate[1];
      }
      // 开票状态
      if (this.retrievalData.invoiceState) {
        params.invoiceState = this.retrievalData.invoiceState;
      }
      // 申请开票方
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      // 有无结算单
      if (typeof this.retrievalData.hasBill != 'string') {
        params.hasBill = this.retrievalData.hasBill;
      }
      this.$post("/biz/new/bill/invoice/export", params).then((res) => {
        const arr = [...res.data];
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(
              "[" + item.fileName + "]已经申请下载,请耐心等待"
            );
          }
        }
      })
        .catch(() => {
          return;
        });
    },
    // 添加/编辑
    addEdit(row) {
      if (row) {
        this.$router.push({
          path: "/web/financialSettlement/invoicingManagementAddEdit",
          query: {
            id: row.invoiceId,
            applicationComp: row.applicationComp,
          },
        });
      } else {
        this.$router.push({
          path: "/web/financialSettlement/invoicingManagementAddEdit",
          query: {
            applicationComp: '10',
            id: '',

          },
        });
      }
    },
    // 驳回
    rejectz(row) {
      this.rejectzledialog = true;
      this.rejectzDta.invoiceId = row.invoiceId;
      this.rejectzDta.remark = row.remark;
    },
    // 驳回 - 确定
    rejectzDetermine() {
      let params = {
        reject: true,
        ...this.rejectzDta,
      };
      this.$post("/biz/new/bill/invoice/reject", params).then((res) => {
        if (res.status == 0) {
          this.rejectzledialog = false;
          Object.keys(this.rejectzDta).forEach((key) => {
            this.rejectzDta[key] = "";
          });
          this.getData(-1);
          this.$message({
            type: "success",
            message: "驳回成功",
          });
        }
      });
    },
    // 完成
    completez(row) {
      let params = {
        complete: true,
        invoiceId: row.invoiceId,
      };
      this.$post("/biz/new/bill/invoice/complete", params).then((res) => {
        if (res.status == 0) {
          this.getData(-1);
          this.$message({
            type: "success",
            message: "操作成功",
          });
        }
      });
    },
    // 打印
    printp(row) {
      this.printploading = true;
      this.$post("/biz/new/bill/invoice/print", {
        invoiceId: row.invoiceId,
      }).then((res) => {
        if (res.status == 0) {
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-cert1");
          });
        }
      });
    },
    //同意
    agreen(invoiceId) {
      this.$post("/biz/new/bill/invoice/agree", {
        agree: true,
        invoiceId,
      }).then((res) => {
        if (res.status == 0) {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getData(-1);
        }
      });
    },
    //上传附件
    uploadFile(invoiceId) {
      this.uploadfiledialog = true;
      this.invoiceId = invoiceId;
      this.getfileList(invoiceId);
    },
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.uploadfileList.push({
            invoiceId: this.invoiceId,
            voucherName: file.name,
            voucherKey: result.data.fileKey,
            fileType: extension,
            voucherUrl: result.data.fileURL,
          });
          console.log(this.uploadfileList);
        })
        .catch((err) => {
          return;
        });
    },
    uploadfileClose() {
      this.uploadfiledialog = false;
      this.fileremark = "";
      this.uploadfileList = [];
    },
    // 上传附件 - 确定
    uploadfileDetermine() {
      let parmar = {
        invoiceId: this.invoiceId,
        invoiceNo: this.InvoiceForm.InvoiceNo,
      };
      parmar.list = this.uploadfileList;

      this.$post("/biz/invoice/voucher/insert", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.uploadfiledialog = false;
            this.fileremark = "";
            this.uploadfileList = [];
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    getfileList(invoiceId) {
      this.$post("/biz/invoice/voucher/getInfo", { invoiceId })
        .then((ret) => {
          this.InvoiceForm.InvoiceNo = ret.data.invoiceNo;
          this.uploadfileList = ret.data.list || [];
        })
        .catch((err) => {
          return;
        });
    },
    reomveExl(index) {
      this.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    lookExl(item) {
      console.log(item.fileUrl);
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.voucherUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = item.voucherUrl;
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例
      if (from.path == "/web/financialSettlement/invoicingManagementAddEdit") {
        vm.getData(vm.pageNum);
      }
    });
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val.query.refresh);
        if (val.query.refresh == 'true') {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}

.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }

  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}

.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}

.bh /deep/ .el-textarea__inner {
  height: 200px;
}

.el-upload {
  height: 180px !important;
}

.upload-btn {
  height: 180px;
}
</style>
